import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Router from "next/router"; // Глобальный маршрутизатор
import { useDispatch } from "react-redux";
import useUser from "../../hooks/useUser"; // Используется для получения состояния пользователя
import { setAuthorization } from "../../utils/redux/actionCreators/actions";
import { CircularProgressIndicator } from "./CircularProgressIndicator/CircularProgressIndicator";
import { useDiscoverData, useRulesData } from "../../utils/apollo/reactivities";

export const RouteGuard = ({ children, authProtected = false }) => {
  const router = useRouter(); // Локальный роутер для работы с текущим путем
  const {
    query: { club },
  } = router;
  const dispatch = useDispatch();

  const previousRoute = useRef({ pathname: null, query: null });

  const isRulesAccepted = useRulesData();
  const isDiscoverAccepted = useDiscoverData();

  const [isLoading, setIsLoading] = useState(true);

  // Данные из хука useUser для авторизации
  const { isAuthorized, isReady, rulesError, discoverClubRequired } = useUser({
    skip: !club, // Пропускаем, если параметр `club` отсутствует
  });

  // Эффект для проверки маршрутов при изменении маршрута
  useEffect(() => {
    if (!isReady) return;

    handleAuthCheck(router.asPath, router.query);
    // handleAuthCheck(router.asPath); // Выполняем проверку при загрузке страницы

    const handleRouteChangeStart = () => {
      // Показываем индикатор загрузки при смене маршрута
      setIsLoading(true);
    };

    const handleRouteChangeComplete = (url) => {
      // const { pathname, query } = parseUrl(url); // Получаем `pathname` и `query` из URL
      // handleAuthCheck(pathname, query); // Проверяем новый путь

      const { pathname, query } = extractPathAndQuery(url);
      handleAuthCheck(pathname, query); // Проверяем новый путь
    };

    // Подписываемся на события маршрутизации
    Router.events.on("routeChangeStart", handleRouteChangeStart);
    Router.events.on("routeChangeComplete", handleRouteChangeComplete);

    // Отписываемся от событий маршрутизации при размонтировании компонента
    return () => {
      Router.events.off("routeChangeStart", handleRouteChangeStart);
      Router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [isReady, isRulesAccepted, isDiscoverAccepted, discoverClubRequired]);

  // Проверка авторизации и маршрутизации
  const handleAuthCheck = (pathname, query) => {
    // Проверяем, если пользователь уже на текущем пути с теми же параметрами
    const oldPreviousPathname = previousRoute.current.pathname;

    const isSamePage =
      previousRoute.current?.pathname === pathname && JSON.stringify(previousRoute.current?.query) === JSON.stringify(query);

    if (!isSamePage) previousRoute.current = { pathname, query };

    const club = getClubNameByPathname(pathname);

    // Если пользователь не авторизован

    if (!isAuthorized) {
      if (authProtected) {
        redirectTo(`/club/${club}`, pathname);
        dispatch(setAuthorization()); // Выполняем экшен авторизации
        return;
      }
      // Если авторизация не требуется
      setIsLoading(false);
      return;
    }

    // Если требуется принятие правил

    if (club && isRulesAccepted === null) return;

    // Проверяем, нужно ли перенаправление на страницу правил
    const rulesPath = `/club/${club}/rules`;

    // console.log(pathname, rulesPath, isRulesAccepted, rulesError?.graphQLErrors?.[0]?.result, "!!!!");

    if (oldPreviousPathname != rulesPath && pathname !== rulesPath && rulesError?.graphQLErrors?.[0]?.result === 7 && !isRulesAccepted) {
      redirectTo(rulesPath, pathname);
      return;
    }

    // Проверяем, нужно ли перенаправление на "выявление" (discover)
    const discoverPath = `/club/${club}/discover`;
    if (
      oldPreviousPathname != discoverPath &&
      pathname !== discoverPath &&
      discoverClubRequired &&
      !isDiscoverAccepted &&
      isRulesAccepted
    ) {
      redirectTo(discoverPath, pathname);
      return;
    }

    // Если все проверки выполнены успешно
    setIsLoading(false);
  };

  const getClubNameByPathname = (pathname, key = "club") => {
    if (!pathname || typeof pathname !== "string") return null;

    const segments = pathname.split("/").filter(Boolean); // Убираем пустые сегменты

    const keyIndex = segments.indexOf(key);

    return keyIndex !== -1 ? segments[keyIndex + 1] || null : null; // Возвращаем следующий сегмент или null
  };

  // const parseUrl = (url) => {
  //   try {
  //     const { pathname, search } = new URL(url, window.location.origin);
  //     const query = Object.fromEntries(new URLSearchParams(search));
  //     return { pathname, query };
  //   } catch (error) {
  //     console.error("Invalid URL:", url);
  //     return { pathname: "", query: {} };
  //   }
  // };
  const extractPathAndQuery = (url) => {
    if (!url || typeof url !== "string") {
      return { pathname: "", query: {} };
    }

    const [pathname, queryString = ""] = url.split("?"); // Разделяем URL на путь и строку запроса
    const query = Object.fromEntries(new URLSearchParams(queryString)); // Преобразуем строку запроса в объект ключ-значение

    return { pathname, query }; // Возвращаем путь и объект с параметрами
  };

  // Утилита для перенаправления
  const redirectTo = (pathname, nextUrl) => {
    setIsLoading(true);
    Router.replace({ pathname, query: { nextUrl } }, pathname);
    // Устанавливаем состояние загрузки
  };

  // Если происходит загрузка, показываем индикатор
  if (isLoading) {
    return <CircularProgressIndicator fullscreen isPortal />;
  }

  // Если страница защищена, рендерим контент только при успешной авторизации
  return isAuthorized || !authProtected ? children : null;
};
